/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Poppins";
}
header {
  background: #fff;
}
header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: lightseagreen;
}
header a {
  color: #333;
  text-decoration: none;
}

.pages{
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}

/* homepage */
.home {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 100px;
}
.workout-details {
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
}
.workout-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.workout-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
.workout-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

/* new workout form */
label, input {
  display: block;
}
input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}

input.error {
  border: 1px solid var(--error);
}

/* Navbar*/
nav {
  display: flex;
  align-items: center;
}

nav a {
  margin-left: 10px;
}


nav button {
  background: var(--primary);
  border: 2px;
  color: #fff;
  padding: 6px 10px;
  font-family: "Poppins";
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

/*Auth form*/
form.signup{
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: darkgray;
  border-radius: 4px;
}

form.login{
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: darkgray;
  border-radius: 4px;
}

/*hover animation on span with class name "matarial ui outlined"  */
.material-symbols-outlined {
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.material-symbols-outlined:hover {
  transform: scale(1.4);
  color: white;
  background-color: var(--error);
}